// Global

#banner {
	background-image: url("img/DSC_0329.png");
	background-repeat: no-repeat;
}

.navbar-inverse {
	-webkit-border-radius: 0 0 4px 4px !important;
	-moz-border-radius: 0 0 4px 4px !important;
	border-radius: 0 0 4px 4px !important;
}

// Calendar Page
.fc-content {
	 white-space: normal !important;
}
.legendBox {
	border: 1px solid #000000;
	border-radius: 5px;
	height: 1.5em;
	text-align: center;
	width: 100px;
}
.auxCommLegend {
	background-color: #3a87ad;
	color: #ffffff;
}
.dsarcLegend {
	background-color: #737373;
	color: #ffffff;
}
.w7uqLegend {
	background-color: #000000;
	color: #FFDF00;
}
.pharcLegend {
	background-color: #f4a742;
	color: #000000;
}